/**
 * @file changelog.jsx
 * @description Changelog page.
 * @author Felix Waßmuth (Felix | D1strict)
 * @license Exclusive property of Felix Waßmuth (Felix | D1strict)
 * @version 0.1.0
 * @since 0.1.0
 * @copyright Felix Waßmuth (Felix | D1strict), 2023
 */

import React from "react";
import Meta from "../components/Meta";
import Hero from "../components/hero";

/**
 * @function ChangelogPage
 * @description Changelog page.
 * @returns {JSX.Element} Changelog page.
 */

function ChangelogPage() {
  return (
    <>
      <Meta
        title="Changelog"
        description="Get the latest news about ACP-Shortcut."
      />
      <Hero title="Changelog" subtitle="Get the latest news about ACP-Shortcut." />
      <div className="section">
        <div className="container">
          <div className="timeline is-centered">
            <header className="timeline-header">
              <span className="tag is-medium">Version 1.0.0</span>
            </header>
            <div className="timeline-item">
              <div className="timeline-marker"></div>
              <div className="timeline-content">
                <p className="heading">
                  <span className="tag">1.0.1</span>{" "}
                  <time dateTime="2021-01-25T11:45:06+01:00">
                    25th January 2021
                  </time>
                </p>
                <p>
                  <span className="has-text-success">&bull;</span> The ACP
                  shortcut can now optionally be opened in a new tab. (
                  <code>Open ACP-Shortcut in a new tab</code>)
                </p>
              </div>
            </div>
            <div className="timeline-item">
              <div className="timeline-marker"></div>
              <div className="timeline-content">
                <p className="heading">
                  <span className="tag">2.0.0</span>{" "}
                  <time dateTime="2021-07-26T22:01:37+02:00">
                    26th July 2021
                  </time>
                </p>
                <p>
                  <span className="has-text-danger">&bull;</span> The minimum
                  version for the WoltLab Suite™ Core has been raised to 5.3.0.
                </p>
              </div>
            </div>
            <div className="timeline-item">
              <div className="timeline-marker is-primary"></div>
              <div className="timeline-content">
                <p className="heading">
                  <span className="tag is-primary">5.4.0</span>{" "}
                  <time dateTime="2022-07-15T12:53:32+02:00">
                    15th July 2022
                  </time>
                </p>
                <p>
                  <span className="has-text-danger">&bull;</span> The minimum
                  version for the WoltLab Suite™ Core has been raised to 5.4.0
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangelogPage;
