/**
 * @file navbar.jsx
 * @description Navbar component.
 * @author Felix Waßmuth (Felix | D1strict)
 * @license Exclusive property of Felix Waßmuth (Felix | D1strict)
 * @version 0.1.0
 * @since 0.1.0
 * @copyright Felix Waßmuth (Felix | D1strict), 2023
 */

import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

/**
 * @function Navbar
 * @returns {JSX.Element} Navbar component.
 */

function Navbar() {
  const location = useLocation();
  useEffect(() => {
    setMenuOpen(false);
  }, [location.pathname]);

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <nav
      className="navbar is-fixed-top"
      id="navbar"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <Link className="navbar-item" to="/">
          <img
            src="/images/logo_acp-shortcut.svg"
            alt="ACP-Shortcut"
            loading="eager"
            style={{ width: "auto", height: "2.5rem" }}
            height="360"
            width="640"
          />
        </Link>

        <a
          role="button"
          className={`navbar-burger burger ${menuOpen ? "is-active" : ""}`}
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarContent"
          onClick={() => setMenuOpen(!menuOpen)}
          href="#navbar"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div
        id="navbarContent"
        className={`navbar-menu ${menuOpen ? "is-active" : ""}`}
      >
        <div className="navbar-start">
          <Link className="navbar-item" to="/">
            Home
          </Link>

          <Link className="navbar-item" to="/changelog">
            Changelog
          </Link>

          <Link className="navbar-item" to="/license">
            License
          </Link>

          <div className="navbar-item has-dropdown is-hoverable">
            <a className="navbar-link" href="#navbar">
              Help &amp; Support
            </a>

            <div className="navbar-dropdown">
              <Link className="navbar-item" to="/faq">
                FAQ
              </Link>
              <a
                className="navbar-item"
                href="https://felix-d1strict.de/support"
              >
                Support
              </a>
            </div>
          </div>
        </div>
        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons">
              <Link className="button is-primary" to="/download">
                <strong>Download</strong>
              </Link>
              <a
                className="button is-primary is-light"
                href="https://www.woltlab.com/pluginstore/file/6822-acp-shortcut/"
                target="_blank"
                rel="noopener noreferrer"
              >
                View on WoltLab
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
