/**
 * @file testimonials.jsx
 * @description Testimonials component.
 * @author Felix Waßmuth (Felix | D1strict)
 * @license Exclusive property of Felix Waßmuth (Felix | D1strict)
 * @version 0.1.0
 * @since 0.1.0
 * @copyright Felix Waßmuth (Felix | D1strict), 2023
 */

import React from "react";
import { Client, Avatars } from "appwrite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { config } from "@fortawesome/fontawesome-svg-core";

/**
 * @function Testimonials
 * @returns {JSX.Element} Testimonials component.
 */

function Testimonials() {
  config.autoAddCss = false;

  const client = new Client();
  const avatars = new Avatars(client);

  client
    .setEndpoint(process.env.REACT_APP_APPWRITE_ENDPOINT) // Your API Endpoint
    .setProject(process.env.REACT_APP_APPWRITE_PROJECT);

  const testimonials = [
    {
      name: "ArnoEdwin",
      text: '<b>ACP-Verknüpfung 1.0.0</b></br>Eine Wunderbare Idee findet hier Seine Umsetzung, aber es ist noch nicht Perfekt! Ich weiss nicht wieviel Arbeit es machen würde, mit dem kleinen Zusatz target"=_blank" das ACP in einem Neuen Tab Anzeigen zu Lassen? Mit dem Freigegebenen Link wird das ACP Leider in dem Gleichen Fenster Geöffnet aus dem Heraus es Gestartet wird. So habe ich leider nicht die Volle Kontrolle ohne das Fenster zu Schliessen, und das was ich Geändert habe ohne Erneuten Aufruf des Fensters zu Betrachten.',
      date: "2021-01-20",
      source: "WoltLab",
      sourceurl:
        "https://www.woltlab.com/pluginstore/file/6821-acp-verkn%C3%BCpfung/#reviews/review167",
      stars: 5,
    },
    {
      name: "BuGiDo",
      text: "<b>Spitze</b></br>Vielen Dank!",
      date: "2021-10-17",
      source: "WoltLab",
      sourceurl:
        "https://www.woltlab.com/pluginstore/file/6821-acp-verkn%C3%BCpfung/#reviews/review254",
      stars: 5,
    },
  ];

  return (
    <div className="section">
      <h2 className="title is-2 has-text-centered">Testimonials</h2>
      <h3 className="subtitle is-4 has-text-centered">
        What others say about the “ACP-Shortcut”-Extension.
      </h3>
      <br />
      <div className="columns is-multiline">
        {testimonials.map((testimonial) => (
          <div className="column is-half" key={testimonial.name}>
            <div className="card">
              <div className="card-content">
                <div className="media">
                  <div className="media-left">
                    <figure className="image is-48x48">
                      <img
                        src={avatars.getInitials(testimonial.name, 96, 96)}
                        alt=""
                        width="48"
                        height="48"
                        loading="lazy"
                      />
                    </figure>
                  </div>
                  <div className="media-content">
                    <p className="title is-4">{testimonial.name}</p>
                    <p className="subtitle is-6">
                      at{" "}
                      <a
                        href={testimonial.sourceurl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {testimonial.source}
                      </a>{" "}
                      (
                      <time dateTime={testimonial.date}>
                        {testimonial.date}
                      </time>
                      )
                    </p>
                  </div>
                </div>
                <div className="content">
                  <blockquote className="card" dangerouslySetInnerHTML={{ __html: "“" + testimonial.text + "”" }}></blockquote>
                  {testimonial.stars > 0 && (
                    <p
                      className="has-text-right"
                    >
                      <span data-tooltip={testimonial.stars + " Stars"}>
                        {[...Array(testimonial.stars)].map((e, i) => (
                          <span className="icon has-text-warning" key={i}>
                            <FontAwesomeIcon icon={faStar} />
                          </span>
                        ))}
                      </span>
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Testimonials;
